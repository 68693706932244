import React, { useCallback } from 'react'
import IconVolumnHalfOn from '@haiper/icons-svg/icons/outline/volume-half-on.svg'
import IconVolumeFullOn from '@haiper/icons-svg/icons/outline/volume-full-on.svg'
import IconVolumeMinimumOn from '@haiper/icons-svg/icons/outline/volume-minimum-on.svg'
import IconVolumeOff from '@haiper/icons-svg/icons/outline/volume-off.svg'
import useVolume from '@/hooks/useVolume'
import { cls, preventDefault, stopPropagation } from '@/utils'
import Button from '../button'

export interface VolumeOnlyControlProps {
  className?: string
  muted?: boolean
  onMute: () => void
  onUnMute: () => void
}

export default function VolumeOnlyControl({ className, muted: propsMuted, onMute, onUnMute }: VolumeOnlyControlProps) {
  const { muted: globalMuted, setMuted, volume, setVolume } = useVolume()
  const muted = propsMuted ?? globalMuted

  const toggleMuted = useCallback(
    (e: any) => {
      preventDefault(e)
      stopPropagation(e)

      if (muted) {
        onUnMute?.()
      } else {
        onMute?.()
      }
    },
    [muted, onMute, onUnMute],
  )

  const IconVolume = muted
    ? IconVolumeOff
    : volume === 0
      ? IconVolumeOff
      : volume < 0.5
        ? IconVolumeMinimumOn
        : volume < 1
          ? IconVolumnHalfOn
          : IconVolumeFullOn

  return (
    <div className={cls('h-[52px] w-full p-2.5 pl-5 flex justify-end items-center', className)}>
      <Button variant='transparent' className='size-8 p-0' onClick={toggleMuted}>
        <IconVolume className='size-5 text-icon-on-color' />
      </Button>
    </div>
  )
}
