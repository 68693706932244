import { cls } from '@/utils'
import IconNSFW from '@haiper/icons-svg/icons/outline/error.svg'
import Button from '@/components/button'
import { PoNVoid } from '@/types'

export interface NSFWProps {
  className?: string
  onView?: (e?: any) => PoNVoid
  outputType: 'video' | 'image'
}

export default function NSFW({ className, onView, outputType }: NSFWProps) {
  return (
    <div
      className={cls(
        '@container absolute inset-0 flex justify-center items-center z-30 text-text backdrop-blur-[50px] rounded-[6px] pointer-events-none bg-surface border',
        className,
      )}
      aria-label='nsfw mask'
    >
      <div
        className='flex flex-col gap-1 @xs:gap-2 @sm:gap-4 items-center justify-between px-2'
        aria-label='nsfw-mask-inner'
      >
        <IconNSFW alt='eye-slash' className='size-8 @xs:size-9 @sm:size-11 @md:size-12 text-icon' />
        <div className='text-body-md leading-4 @xs:leading-5 tracking-15 text-center'>
          Your creation may contain sensitive content
        </div>
        <Button
          variant='transparent'
          className='rounded-md border border-solid border-border pointer-events-auto text-body-md h-7 @xs:h-8 @sm:h-10'
          onClick={onView}
        >
          {/* Watch {outputType === 'video' ? 'video' : 'images'} */}
          Show
        </Button>
      </div>
    </div>
  )
}
