'use client'

import { cls, whisper } from '@/utils'
import IconError from '@haiper/icons-svg/icons/outline/error.svg'
import Dialog from '../dialog'
import { useCallback, useState } from 'react'
import { appealIllegal } from '@/service/creation.service'
import { toast } from '../ui/use-toast'

export interface IllegalProps {
  className?: string
  creationId: string
}

const buttonProps = { className: 'flex-1 rounded-md' }

export default function Illegal({ className, creationId }: IllegalProps) {
  const [showAppealDialog, setShowAppealDialog] = useState(false)

  const handleAppealIllegal = useCallback(async () => {
    const res = await appealIllegal(creationId)
    toast({
      title: 'Sucessfully reported',
      color: 'success',
    })
    setShowAppealDialog(false)
  }, [creationId])

  return (
    <div
      className={cls(
        'absolute inset-0 flex justify-center items-center z-30 text-text backdrop-blur-[50px] rounded-md pointer-events-none bg-transparent p-2 border overflow-hidden',
        className,
      )}
      aria-label='illegal mask'
    >
      <div className='flex flex-col gap-4 @md:gap-6 items-center justify-between' aria-label='nsfw-mask-inner'>
        <IconError alt='eye-slash' className='size-8 @xs:size-9 @sm:size-11 @md:size-12 text-icon' />
        <div className='flex flex-col gap-2 items-center text-body-md tracking-15 text-center leading-4 @xs:leading-5'>
          <span className=''>This content may violate our Terms and Conditions.</span>
          <span>
            Did we get it wrong? Please
            <span
              className='px-1 underline hover:opacity-80 cursor-pointer pointer-events-auto'
              onClick={() => setShowAppealDialog(true)}
            >
              tell us
            </span>
            .
          </span>
        </div>
        <div className='h-0'></div>
      </div>
      <Dialog
        open={showAppealDialog}
        title='Report'
        titleClassName='text-heading-lg font-medium tracking-45'
        okText='Report'
        cancelText='Cancel'
        okButtonProps={buttonProps}
        cancelButtonProps={buttonProps}
        onOk={handleAppealIllegal}
        onOpenChange={setShowAppealDialog}
      >
        <div className='w-full flex flex-col mb-8 mt-1'>
          <span>
            Please avoid generating content that may violate the law. You are legally responsible for the content you
            create.
          </span>
          <br />
          <span>If you believe this content is safe, let us know by reporting it.</span>
        </div>
      </Dialog>
    </div>
  )
}
