import { Switches } from '@/types'
import { useCachedConfig } from './useConfig'
import { isStage } from '@/utils'

export const formatSwitches = (switches: Switches | undefined | null): Switches | undefined | null => {
  if (!switches) {
    return switches
  }

  const keys = Object.keys(switches)
  const formattedSwitches: any = {}
  for (const key of keys) {
    const value: any = (switches as any)[key]
    formattedSwitches[key] = value === true

    if (isStage && !formattedSwitches[key]) {
      formattedSwitches[key] = ['stage', 'staging'].includes(value)
    }
  }

  return formattedSwitches
}

// const useSwitches = () => {
//   return useConfig<Switches>('switches')
// }

export const useCachedSwitches = () => {
  const { data, ...others } = useCachedConfig<Switches>('switches')
  return {
    data: formatSwitches(data),
    ...others,
  }
}

// export default useSwitches
